import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, InputGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { utils as XLSXUtils, writeFile as writeXLSX } from 'xlsx';
import Loader from '../../utils/Loader';
import 'react-confirm-alert/src/react-confirm-alert.css';
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";

function Paymentlist() {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isPostedChecked, setPostedChecked] = useState(false);

  const pageSize = 20;

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    setFromDate(firstDayOfMonth.toISOString().split('T')[0]);
    setToDate(today.toISOString().split('T')[0]);

    fetchPayments();
  }, []);

  const fetchPayments = async (filters = {}) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/Payment/GetPaymentList`,
        {
          params: {
            description: filters.PaymentName || '',
            Offset: filters.offNum || 0,
            RecordCount: pageSize,
            posted: isPostedChecked ? '1' : '0',
          },
        }
      );
      setData(data.data);
      setTotalPages(data.totalRecords);
    } catch (error) {
      console.error('Error fetching payments:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = async (isChecked, id) => {
    try {
      setLoading(true);
      const upload = { Id: parseInt(id) };
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Payment/UpdatePost`, upload);

      toast.success("Status updated successfully");
      fetchPayments();
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchPayments({ PaymentName: e.target.value });
      setSearch(e.target.value);
    }
  };

  const changePage = (value) => {
    const offset = (value - 1) * pageSize;
    setPagination(offset);
    setCurrentPage(value);
    fetchPayments({ offNum: offset });
  };

  const exportToExcel = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/Payment/GetPaymentExcelList`, {
        params: {
          description: search,
          FromDate: fromDate,
          ToDate: toDate,
        },
      });
      setExcelData(data.data);

      const ws = XLSXUtils.json_to_sheet(data.data);
      const wb = XLSXUtils.book_new();
      XLSXUtils.book_append_sheet(wb, ws, 'SheetName');
      writeXLSX(wb, 'exported_data.xlsx');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      toast.error("Failed to export data to Excel");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  };

  const addPayment = () => navigate('/add-Payment');
  const editPayment = (id) => navigate('/edit-payment', { state: { id } });

  const ceil = Math.ceil(totalPages / pageSize);

  return (
    <>
      <Loader loading={loading} />
      <div className="col-xl-12">
        <div className="card custom-card">
          <div className="card-header justify-content-between">
            <div className="card-title">Payments</div>
            <div className="ms-auto">
              <label htmlFor="fromDate">From Date:</label>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="ms-auto">
              <label htmlFor="toDate">To Date:</label>
              <input
                type="date"
                id="toDate"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div className="ms-auto">
              <button className="border-primary btn btn-outline-primary" onClick={exportToExcel}>
                <i className="fe fe-download me-2"></i>Excel Download
              </button>
            </div>
            <div className="prism-toggle">
              <button className="btn btn-sm btn-primary-light" type="button" onClick={addPayment}>
                Add New<i className="ri-code-line ms-2 d-inline-block align-middle"></i>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="gridjs-search mt-1 mb-2">
                <input
                  type="search"
                  placeholder="Type a keyword..."
                  name="PaymentName"
                  onKeyDown={handleSearchKeyDown}
                  className="gridjs-input gridjs-search-input"
                />
              </div>
              <Table className="gridjs-table">
                <thead>
                  <tr>
                    <th>Expense Group</th>
                    <th>Description</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Mode</th>
                    <th>
                      <input
                        type="checkbox"
                        checked={isPostedChecked}
                        onChange={() => {
                          setPostedChecked(!isPostedChecked);
                          fetchPayments();
                        }}
                      />
                      Posted
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((contact, index) => (
                    <tr key={index}>
                      <td>{contact.expenseType}</td>
                      <td>{contact.description}</td>
                      <td>{formatDate(contact.paymentDate)}</td>
                      <td>{contact.amount}</td>
                      <td>{contact.mode}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={contact.posted}
                          onChange={() => handleCheckboxChange(contact.posted, contact.id)}
                        />
                      </td>
                      <td>
                        <div className="hstack gap-2 fs-15">
                          <button className="btn btn-icon btn-sm btn-light" onClick={() => editPayment(contact.id)}>
                            <img src={edit} alt="Edit" />
                          </button>
                          <button className="btn btn-icon btn-sm btn-light">
                            <img src={del} alt="Delete" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="gridjs-footer">
              <div className="gridjs-pagination">
                <ResponsivePagination
                  current={currentPage}
                  total={ceil}
                  onPageChange={changePage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paymentlist;
