import React, { FC, Fragment, useState, useEffect ,useRef} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import 'filepond/dist/filepond.min.css';
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from 'axios';
import Loader from '../../utils/Loader';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../assets/images/search.png";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import invoice from "../../assets/images/inv.jpg";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import XLSX from 'xlsx';
import { utils as XLSXUtils, writeFile as writeXLSX } from 'xlsx';




function Triplist() {
  const navigate = useNavigate()
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [pagination, setPagination] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const pageSize = 20;
    const [modalId, setModalId] = useState(null);
    const invoiceNoRef = useRef(null);
    const paidamountRef = useRef(null);
    const paidremarksRef = useRef(null);
    const [editable, setEditable] = useState({ index: null, field: null });
    const [commissionFilter, setCommissionFilter] = useState('');
    const [invoiceFilter, setInvoiceFilter] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [inputValues, setInputValues] = useState({
      TripName: '',
      InvNo: '',
      LoadingFrom: '',
      DeliverTo: '',
      Driver: '',
    });
  
  const schema = yup.object().shape({
    TripName: yup.string().required("Trip Name required"),
    
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [excelData, setExcelData] = useState([])
  useEffect(() => {
    onSubmit()
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);

    setFromDate(firstDayOfMonth.toISOString().split('T')[0]);
    setToDate(today.toISOString().split('T')[0]);
  }, [])

  const onSubmit = async (value) => {
    setLoading(true)
    

    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Trip/GetTripList?Name=${value?.TripName ? value?.TripName : ''}&Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}&InvNo=${value?.InvNo ? value?.InvNo : ''}&LoadingFrom=${value?.LoadingFrom ? value?.LoadingFrom : ''}&DeliverTo=${value?.DeliverTo ? value?.DeliverTo : ''}&Driver=${value?.Driver ? value?.Driver : ''}&Commission=${value?.commissionFilter ? value?.commissionFilter : ''}&Invoice=${value?.invoiceFilter ? value?.invoiceFilter : ''}`,
      method: 'GET',
    }).then((response) => {
      setLoading(false)
      setData(response.data.data)
      setTotalPages(response.data.totalRecords);
      //setExcelData(response.data.data)
    })
      .catch((error) => {
        setLoading(false)
      })
  };


  const getDisplayedPages = (current, total) => {
    const maxPagesToShow = 10;
    const half = Math.floor(maxPagesToShow / 2);
    let start = Math.max(current - half, 1);
    let end = Math.min(current + half, total);

    if (current <= half) {
      end = Math.min(maxPagesToShow, total);
    } else if (current + half >= total) {
      start = Math.max(total - maxPagesToShow + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const displayedPages = getDisplayedPages(currentPage, totalPages);
  

//   const onSubmitExcel = async (value) => {
//     setLoading(true)
   

//     // axios({
//     //   url: `${process.env.REACT_APP_SERVER_URL}/api/Trip/GetTripExcelList?Name=${value?.TripName ? value?.TripName : ''}&Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}&InvNo=${value?.InvNo ? value?.InvNo : ''}&LoadingFrom=${value?.LoadingFrom ? value?.LoadingFrom : ''}&DeliverTo=${value?.DeliverTo ? value?.DeliverTo : ''}&Driver=${value?.Driver ? value?.Driver : ''}&Commission=${value?.commissionFilter ? value?.commissionFilter : ''}&Invoice=${value?.invoiceFilter ? value?.invoiceFilter : ''}&FromDate=${value?.FromDate ? value?.FromDate : ''}&ToDate=${value?.ToDate ? value?.ToDate : ''}`,
//     //    method: 'GET',
//     // }).then((response) => {
//     //   setLoading(false)
//     //   setExcelData(response.data.data)
//     //   if(excelData.length>0){
//     //   const ws = XLSXUtils.json_to_sheet(excelData);
//     //   const wb = XLSXUtils.book_new();
//     //   XLSXUtils.book_append_sheet(wb, ws, 'SheetName'); // Provide a sheet name
    
//     //   writeXLSX(wb, 'exported_data.xlsx');
//     //   setExcelData([]);
//     //   }
    
//     // })
//     const response = await axios({
//       url: `${process.env.REACT_APP_SERVER_URL}/api/Trip/GetTripExcelList`,
//       method: 'GET',
//       params: {
//         Name: value?.TripName || '',
//         Offset: value?.offNum || 0,
//         RecordCount: pageSize,
//         InvNo: value?.InvNo || '',
//         LoadingFrom: value?.LoadingFrom || '',
//         DeliverTo: value?.DeliverTo || '',
//         Driver: value?.Driver || '',
//         Commission: value?.commissionFilter || '',
//         Invoice: value?.invoiceFilter || '',
//         FromDate: value?.FromDate || '',
//         ToDate: value?.ToDate || ''
//       }
//     });

//     // Set fetched data to state
//     setExcelData(response.data.data);

//     // Check if data exists
//     if (response.data.data.length > 0) {
//       // Create workbook and worksheet
//       const ws = XLSX.utils.json_to_sheet(response.data.data);
//       const wb = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(wb, ws, 'SheetName'); // Provide a sheet name

//       // Write the workbook to a file
//       XLSX.writeFile(wb, 'exported_data.xlsx');

//       // Clear excel data state
//       setExcelData([]);
//     }

//     // Hide loading indicator
//     setLoading(false);
//   } 


//   catch (error) {
//     console.error('Error fetching data or creating Excel file:', error);

//     // Hide loading indicator
//     setLoading(false);
//   }
// }

async function onSubmitExcel(value) {
  try {
    // Show loading indicator
    setLoading(true);

    // Fetch data from API

    setTimeout(async () => {
      
      console.log('Excel data prepared.');
 
    const response = await axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Trip/GetTripExcelList`,
      method: 'GET',
      params: {
        Name: value?.TripName || '',
        Offset: value?.offNum || 0,
        RecordCount: pageSize,
        InvNo: value?.InvNo || '',
        LoadingFrom: value?.LoadingFrom || '',
        DeliverTo: value?.DeliverTo || '',
        Driver: value?.Driver || '',
        Commission: value?.commissionFilter || '',
        Invoice: value?.invoiceFilter || '',
        FromDate: value?.FromDate || '',
        ToDate: value?.ToDate || ''
      }
    });

    // Set fetched data to state
    setExcelData(response.data.data);

  }, 3000); 


    // Check if data exists
    // if (response.data.data.length > 0) {
    //   // Create workbook and worksheet
    //   const ws = XLSX.utils.json_to_sheet(excelData);
    //   const wb = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(wb, ws, 'SheetName'); // Provide a sheet name

    //   // Write the workbook to a file
    //   XLSX.writeFile(wb, 'exported_data.xlsx');

    //   // Clear excel data state
    //   setExcelData([]);
    // }

    // Hide loading indicator
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data or creating Excel file:', error);

    // Hide loading indicator
    setLoading(false);
  }
}


  const changePage = (value) => {
    let temp = (value - 1) * 20;
    setPagination(temp);
    setCurrentPage(value);
    //onSubmit({ offNum: temp });
    const commissionFilter = document.getElementById('commissionFilter').value;
    const invoiceFilter = document.getElementById('invoiceFilter').value;
    onSubmit({ TripName: inputValues.TripName, InvNo: inputValues.InvNo, LoadingFrom: inputValues.LoadingFrom, DeliverTo: inputValues.DeliverTo, Driver: inputValues.Driver, offNum: temp ,commissionFilter:commissionFilter,invoiceFilter:invoiceFilter});
};
const ceil = Math.ceil(totalPages / pageSize);
  const addmore = () => {
    navigate('/add-Trip')
  }
  const editfunction = (data) => {
    navigate('/edit-Trip', { state: { id: data } });
  }
  const deletefunction = async (id) => {
    const upload = {
      Id: parseInt(id),
    }
    setLoading(true)
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            try {
              axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/Trip/DeleteTripById`, {
                data: upload,
              }).then((response) => {
                toast.success(response.data.message);
                setLoading(false);
                window.location.reload();
              }).catch((error) => {
                setLoading(false);
                console.log(error);
              });
            } catch (error) {
              setLoading(false);
              console.log(error);
            }
          },
        },
        {
          label: 'No',
          onClick: () => alert('Click No'),
        },
      ],
    });
  }
  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === 'Enter') {
      const filterObject = {};
    fields.forEach((field) => {
      filterObject[field.name] = inputValues[field.name];
    });

    // Call your submit function here with the filter object
    onSubmit(filterObject);
   //onSubmit({ TripName: inputValues.TripName, InvNo: inputValues.InvNo, LoadingFrom: inputValues.LoadingFrom, DeliverTo: inputValues.DeliverTo, Driver: inputValues.Driver, offNum: 0 ,filterObject});
   
    }
  };

  const handleSelect = (e) => {
    // Check if the pressed key is "Enter"

      const filterObject = {};
    fields.forEach((field) => {
      filterObject[field.name] = inputValues[field.name];
    });

    // Call your submit function here with the filter object
    onSubmit(filterObject);
   //onSubmit({ TripName: inputValues.TripName, InvNo: inputValues.InvNo, LoadingFrom: inputValues.LoadingFrom, DeliverTo: inputValues.DeliverTo, Driver: inputValues.Driver, offNum: 0 ,filterObject});
   
    
  };
 
  const handleAddInvoice = async (data) => {
    const upload = {
        userId: "string",
        InvoiceDate: invoiceDate,
        InvoiceNo: invoiceNoRef.current.value,
        Id:parseInt(modalId),
        
    }
    setLoading(true)
    try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Trip/UpdateTripInvoice`, upload);
        if (response.data.status == 1) {
            toast.success(response.data.message);
            navigate('/trip-list')
            setLoading(false)
        }

    } catch (error) {
        setLoading(false)
        console.log(error);
    }
}


const handleAddPaidAmount = async (data) => {
  const upload = {
      userId: "string",
      paidAmount: paidamountRef.current.value,
      paidremarks: paidremarksRef.current.value,
      Id:parseInt(modalId),
      
  }
  setLoading(true)
  try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Trip/UpdateTripPaidAmount`, upload);
      if (response.data.status == 1) {
          toast.success(response.data.message);
          navigate('/trip-list')
          setLoading(false)
      }

  } catch (error) {
      setLoading(false)
      console.log(error);
  }
}


const invoicefunction = (id) => {
  setModalId(id);
  
};
const paidfunction = (id) => {
  setModalId(id);
  
};
const fields = [
  { name: 'TripName', placeholder: 'Company', width: '150' },
  { name: 'InvNo', placeholder: 'Invoice No', width: '100' },
  { name: 'LoadingFrom', placeholder: 'Loading From', width: '150' },
  { name: 'DeliverTo', placeholder: 'Deliver To', width: '150' },
  { name: 'Driver', placeholder: 'Driver Details', width: '150' },
];

const handleInvoiceKeyDown = (e, fieldName,id,rowIndex) => {
  console.log(rowIndex)
 
  if (e.key === 'Enter') {
    // Manually trigger the checkbox click event
    const checkbox = document.getElementById(`checkbox-${rowIndex}`);
 
    if (checkbox) {
      checkbox.checked = !checkbox.checked;
    }
  }


};
const handleCheckboxChange = (index) => {
  const updatedCheckboxes = [...selectedCheckboxes];
  if (updatedCheckboxes.includes(index)) {
    updatedCheckboxes.splice(updatedCheckboxes.indexOf(index), 1);
  } else {
    updatedCheckboxes.push(index);
  }
  setSelectedCheckboxes(updatedCheckboxes);
};



const handleInputChange = (e, fieldName) => {
  const value = e.target.value;

  // Update the state with the new dropdown value
  setInputValues((prevValues) => ({
    ...prevValues,
    [fieldName]: value,
  }));

  // Call your submit function here with the updated filter object
  onSubmit({ ...inputValues, [fieldName]: value });
};



const exportToExcel = async () => {
 // onSubmitExcel();
 const commissionFilter = document.getElementById('commissionFilter').value;
    const invoiceFilter = document.getElementById('invoiceFilter').value;
 await  onSubmitExcel({ TripName: inputValues.TripName, InvNo: inputValues.InvNo, LoadingFrom: inputValues.LoadingFrom, DeliverTo: inputValues.DeliverTo, Driver: inputValues.Driver, offNum: 0,commissionFilter:commissionFilter,invoiceFilter:invoiceFilter ,FromDate:fromDate,ToDate:toDate});

if(excelData!=null){
   const ws = XLSXUtils.json_to_sheet(excelData);
 const wb = XLSXUtils.book_new();
 XLSXUtils.book_append_sheet(wb, ws, 'SheetName'); // Provide a sheet name
   writeXLSX(wb, 'exported_data.xlsx');
   setExcelData([]);
}

};

const handleSave = () => {
  const selectedData = selectedCheckboxes.map((index) => data[index]);
  console.log('Selected Rows:', selectedData);
  handleUpdateInvoiceBulk(selectedData)
  
};
const handleInvoiceChange = (e,index, fieldName) => {
  const newData = [...data];
  newData[index][fieldName] = e.target.value;
  setData(newData);
  setInputValues((prevValues) => ({
    ...prevValues,
    [fieldName]: e.target.value,
  }));
};
const handleDateChange = (date, index, fieldName) => {
  // Update state or perform actions as needed
  const newData = [...data];
  newData[index][fieldName] = date.toISOString(); // Convert the date to a string if needed
  setData(newData);
};

const handleAwbChange = (e,index, fieldName) => {
  const newData = [...data];
  newData[index][fieldName] = e.target.value;
  setData(newData);
  setInputValues((prevValues) => ({
    ...prevValues,
    [fieldName]: e.target.value,
  }));
};

const handleAwbKeyDown = (e, fieldName,id,rowIndex) => {
  console.log(rowIndex)
 
  if (e.key === 'Enter') {
    // Manually trigger the checkbox click event
    const checkbox = document.getElementById(`checkbox-${rowIndex}`);
 
    if (checkbox) {
      checkbox.checked = !checkbox.checked;
    }
  }


};


const handleUpdateInvoiceBulk = async (data) => {

  setLoading(true)
  const transformedData = transformToApiFormat(data);
  console.log(data.awbNo)
  try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Trip/UpdateTripInvoiceBulk`, transformedData);
      if (response.data.status == 1) {
          toast.success(response.data.message);
          navigate('/trip-list')
          setLoading(false)
          setSelectedCheckboxes([]);
      }

  } catch (error) {
      setLoading(false)
      console.log(error);
  }
}

const transformToApiFormat = (data) => {
  return { trip_update: data };
};

const handleRowClick = (index) => {
  console.log("selected Row"+index)
  const selectedIndex = selectedRows.indexOf(index);
  let newSelected = [...selectedRows];

  if (selectedIndex === -1) {
    newSelected.push(index);
  } else {
    newSelected.splice(selectedIndex, 1);
  }

  setSelectedRows(newSelected);
};

  return (
    <>
     <style>{floatingButtonStyles}</style>
      <Loader loading={loading} />
      <div className="floating-buttons">
        <button className="btn-floating" onClick={handleSave}>
          <i className="fe fe-save me-2"></i>
        </button>
      </div>
      <div class="col-xl-12">
        <div class="card custom-card">
          <div class="card-header justify-content-between">
            <div class="card-title">
              Trips
            </div>
            <div class="ms-auto">
              <label htmlFor="fromDate">From Date:</label>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div class="ms-auto">
              <label htmlFor="toDate">To Date:</label>
              <input
                type="date"
                id="toDate"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div class="ms-auto">
              <button class="border-primary btn btn-outline-primary" onClick={exportToExcel} >
              <i class="fe fe-download me-2"></i>Excel Download</button> 
             </div>
            <div class="prism-toggle">
              <button class="btn btn-md btn-primary-light" type='button' onClick={addmore}>Add New<i class="fe fe-plus me-2"></i></button>
         
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
         
              <div class="gridjs-search mt-1 mb-2">
           
              {fields.map((field) => (
          <input
            key={field.name}
            type="search"
            placeholder={field.placeholder}
            name={field.name}
            onKeyDown={(e) => handleKeyDown(e, field.name)}
            onChange={(e) => handleInputChange(e, field.name)}
            value={inputValues[field.name]}
            aria-label={`Type a keyword for ${field.placeholder}`}
            className={`gridjs-input gridjs-search-input`}
            style={{ width: field.width+'px' }}
          />
          
        ))}
            <select
             id='commissionFilter'
  onChange={(e) => {handleInputChange(e, 'commissionFilter')}}
  value={inputValues.commissionFilter}
  className="gridjs-input gridjs-search-input"
  style={{ width: '100px', height: 30 }}
>
  <option value="">All (Commission)</option>
  <option value="1">With Commission</option>
  <option value="0">Without Commission</option>
</select>


        <select
        id='invoiceFilter'
          onChange={(e) => {handleInputChange(e, 'invoiceFilter')}}
          value={inputValues.invoiceFilter}
          className={`gridjs-input gridjs-search-input${'width' ? '-m' : ''}`}
          style={{ width: '100px',height:30 }} // Adjust the width as needed
        >
          <option value="">All (Invoice)</option>
          <option value="1">With Invoice</option>
          <option value="0">Without Invoice</option>
        </select>
            
        </div>
        

  
   
              <table class="gridjs-table " >
                <thead>
                  <tr>
                    {/* <th className='gridjs-th'>Sl NO</th> */}
                    <th className='gridjs-th' >Action</th>
                    <th className='gridjs-th' >Id</th>
                    <th className='gridjs-th' ></th>
                    <th className='gridjs-th' ></th>
                    <th className='gridjs-th'  >Trip Date</th>
                    <th className='gridjs-th'>Company</th>
                    <th className='gridjs-th'>Invoice No. </th>
                    <th className='gridjs-th'>Invoice Date </th>
                    <th className='gridjs-th'>Awb No</th>
                    <th className='gridjs-th'>Remarks </th>
                    <th className='gridjs-th'>Loading From </th>
                    <th className='gridjs-th'>Deliver To </th>
                    <th className='gridjs-th'>Veh Type </th>
                    <th className='gridjs-th'>No. of Trips </th>
                    <th className='gridjs-th'>Payment Status </th>
                    <th className='gridjs-th'>Date of Payment </th>
                    <th className='gridjs-th'>Trip Charge </th>
                    <th className='gridjs-th'>Invoice Amount </th>
                    <th className='gridjs-th'>Commission </th>
                    <th className='gridjs-th'>Profit </th>
                    <th className='gridjs-th'>Paid Amount </th>
                    <th className='gridjs-th'>Paid Remarks </th>
                    <th className='gridjs-th'>Balance Amount </th>
                    <th className='gridjs-th'>Detention </th>
                    <th className='gridjs-th'>Extra Delivery </th>
                    <th className='gridjs-th'> </th>
                    <th className='gridjs-th'>Driver</th>
                    <th className='gridjs-th'>Mobile</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {data?.map((contact, index) => {
                     const isChecked = selectedCheckboxes.includes(index);
                    return (
                      <tr key={index} className={selectedRows.includes(index) ? 'selected-row' : ''} onClick={() => handleRowClick(index)}>
                            <td class="gridjs-td">
                          <div class="hstack gap-2 fs-15">
                            <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.id)} ><img src={edit} /></a>
                            {/* <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(contact?.id)}><img src={del} /></a> */}
                            {/* <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} ><img src={invoice} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => invoicefunction(contact?.id)}/></a> */}
                          </div>
                        </td>
                           
                           <td class="gridjs-td">{contact.id}</td>
                          <td className="gridjs-td">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheckboxChange(index)}
          id={`checkbox-${index}`}
        />
      </td>
                        {/* <td class="gridjs-td">{index + 1}</td> */}
                        <td class="gridjs-td">
                          <div class="hstack gap-2 fs-15">
                            <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} ><img src={invoice} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => invoicefunction(contact?.id)}/></a>
                          </div>
                        </td>
                        <div className="scrollable-column">
    {contact.tripDate ? contact.tripDate.split('T')[0] : ''}
  </div>
                        <td class="gridjs-td">{contact.company}</td>
                        <td className="gridjs-td">
             
                <input
                  type="text"
                  value={contact.invoiceNo||''}
                  onChange={(e) => handleInvoiceChange(e, index, 'invoiceNo')}
                  onKeyDown={(e) => handleInvoiceKeyDown(e, index, 'invoiceNo', contact.id,index)}
                />
              
            </td>
            <td className="gridjs-td"   >
                <div className="input-group rounded flex-nowrap" style={{ width: '100px' }}>
                  <DatePicker
                   selected={contact.invoiceDate ? new Date(contact.invoiceDate) : null} // Assuming contact.invoiceDate is a valid date string
                    onChange={(date) => handleDateChange(date, index, 'invoiceDate')}
                    dateFormat="MM/dd/yyyy"
                    className="form-control form-control-xsm text-center w-100 p-3"
                    placeholderText="MM/dd/yyyy"
                  />
                </div>
              </td>
            {/* <td className="gridjs-td">
                {editable.index === index && editable.field === 'invoiceDate' ? (
                  <DatePicker
                    selected={new Date(contact.invoiceDate)} // Assuming contact.invoiceDate is a valid date string
                    onChange={(date) => handleDateChange(date, index, 'invoiceDate')}
                  />
                ) : (
                  contact.invoiceDate
                )}
              </td> */}
            {/* <td className="gridjs-td">
              {editable.index === index && editable.field === 'awbNo' ? (
                <input
                  type="text"
                  value={contact.awbNo}
                  onChange={(e) => handleInputChange(e, index, 'awbNo')}
                  onKeyDown={(e) => handleKeyDown(e, index, 'awbNo', contact.id)}
                />
              ) : (
                contact.awbNo
              )}
            </td> */}
            <td class="gridjs-td">
             <input
                  type="text"
                  value={contact.awbNo}
                  onChange={(e) => handleAwbChange(e, index, 'awbNo')}
                  onKeyDown={(e) => handleAwbKeyDown(e, index, 'awbNo', contact.id,index)}
                  />
                  </td>
                
                        <td class="gridjs-td">{contact.remarks}</td>
                        <td class="gridjs-td">{contact.loadingFrom}</td>
                        <td class="gridjs-td">{contact.deliveryTo}</td>
                        <td class="gridjs-td">{contact.vehicleType}</td>
                        <td class="gridjs-td">{contact.noOfTrips}</td>
                        <td class="gridjs-td">{contact.paymentStatus}</td>
                        <td class="gridjs-td">{contact.paymentDate ? contact.paymentDate.split('T')[0] : ''}</td>
                        <td class="gridjs-td">{contact.tripCharge}</td>
                        <td class="gridjs-td">{contact.invoiceAmount}</td>
                        <td class="gridjs-td">{contact.commission}</td>
                        <td class="gridjs-td">{contact.profit}</td>
                        <td class="gridjs-td">{contact.paidAmount}</td>
                        <td class="gridjs-td">{contact.paidRemarks}</td>
                        <td class="gridjs-td">{contact.paidBalance}</td>
                        <td class="gridjs-td">{contact.detention}</td>
                        <td class="gridjs-td">{contact.extra_delivery}</td>
                        <td class="gridjs-td">
                          <div class="hstack gap-2 fs-15">
                            <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} ><img src={invoice} data-bs-toggle="modal" data-bs-target="#paidModal" onClick={() => paidfunction(contact?.id)}/></a>
                          </div>
                        </td>
                        
                        <td class="gridjs-td">{contact.driver}</td>
                        <td class="gridjs-td">{contact.driverMobile}</td>

                    
                       
                       
                      </tr>
                    )
                  })}

                </tbody>
              </table>
       
              </div>
              <div class="gridjs-footer"><div className="gridjs-pagination">
                                <div className="gridjs-pages">
                                    <ResponsivePagination
                                        current={currentPage}
                                        total={ceil}
                                        onPageChange={(value) => changePage(value)}
                                        displayedPages={displayedPages} 
                                    />
                                </div>
                            </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                           
                            <div className="mt-1">

                            </div>
                            <div className="row mt-1">
                            <div className="col-6">
                                    <label>Invoice No</label>
                                    <div className="input-group rounded flex-nowrap">
                                        <input type="text"  className="form-control form-control-sm text-center w-100 p-3" name="invoiceno" aria-label="Invoice No" id="invoiceno"ref={invoiceNoRef}  />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label>Unit</label>
                                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                        <DatePicker selected={invoiceDate} onChange={(date) => setInvoiceDate(date)} />
                                    </div>
                                </div>
                        
                       
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddInvoice}>Update</button>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
             
   

                    <div className="modal fade" id="paidModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Paid</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                           
                            <div className="mt-1">

                            </div>
                            <div className="row mt-1">
                            <div className="col-6">
                                    <label>Paid Amount</label>
                                    <div className="input-group rounded flex-nowrap">
                                        <input type="number"  className="form-control form-control-sm text-center w-100 p-3" name="paidamount" aria-label="Amount" id="paidamount"ref={paidamountRef}  />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label>Paid Remarks</label>
                                    <div className="input-group rounded flex-nowrap">
                                        <input type="text"  className="form-control form-control-sm text-center w-100 p-3" name="paidremarks" aria-label="Remarks" id="paidremarks"ref={paidremarksRef}  />
                                    </div>
                                </div>
                        
                       
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddPaidAmount}>Update</button>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>


    </>
  )
}
const floatingButtonStyles = `
    .floating-buttons {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1000;
    }

    .floating-buttons button {
      margin-top: 10px;
    }

    .btn-floating {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.3s ease;
      background-color: #17a2b8;
    }

    .btn-floating:hover {
      background-color: #17a2b8; /* Change this color to the desired hover effect */
    }
   
    .scrollable-column {
      width: 100px; /* Adjust the width as needed */
      overflow-x: fixed;
    }
  `;
  

export default Triplist
